$(function () {
  var menu = $('nav.obis-site-mainmenu');
  menu.find('.major-menu-item').on('mouseenter mouseleave', function (event) {
    var submenu = $(this).children('ul.submenu');
    var transDuration = (submenu.children().length * 100) + 50;
    submenu.stop();
    if (event.type === 'mouseenter') {
      submenu.slideDown(transDuration, 'linear', function () {
        if (submenu.offset().left + submenu.width() > window.innerWidth) {
          submenu.css('right', '100%');
          submenu.css('left', 'unset');
        }
        submenu.css('visibility', 'visible');
      });
    } else {
      submenu.slideUp(transDuration);
    }
  });
});