$(function () {
  var currentRunningSearchRequest = null;
  function cancelCurrentRequestIfAvailable(message) {
    if (currentRunningSearchRequest !== null) {
      if (message) {
        console.warn(message);
        // console.log(currentRunningSearchRequest);
      }

      currentRunningSearchRequest.abort();
    }
  }

  $('.obis-site-searchbox').each(function (index, elem) {
    var container = $(elem);
    var input = $(elem).find('input.search-input-field');
    var button = $(elem).find('button.do-search');
    var rootNode = container.data('root-node') || null;

    var resultWrapper = container.find('.search-results-wrapper > ul.search-results');
    resultWrapper.slideUp(0);

    var itemTemplate = resultWrapper.siblings('template').first().html().trim();
    var loaderTemplate = '<div class="loader"><i class="far fa-compass fa-spin"></i></div>';
    var nothingFoundTemplate = '<div class="notice"><p>Zu Ihrer Suche konnten leider keine Ergbnisse gefunden werden!</p></div>'

    button.on('click', function (event) {
      var searchTerm = input.val();
      input.focus();

      if (!container.hasClass('active')) {
        container.addClass('active');
      } else if (searchTerm.length > 0) {
        cancelCurrentRequestIfAvailable('Cancelled redundant search request.');

        currentRunningSearchRequest = $.ajax({
          type: 'post',
          url: '/search',
          data: {
            searchTerm: searchTerm,
            rootNode: rootNode,
          },
          dataType: 'json',
          global: false,
          beforeSend: function (jqxhr, settings) {
            if (!resultWrapper.find('.loader').get().length) {
              resultWrapper.children().remove();
              resultWrapper.append(loaderTemplate);
            }

            if (resultWrapper.get(0).style.display === 'none') {
              resultWrapper.slideDown();
            }
          },
          success: function (data, status, jqxhr) {
            if (status === 'success') {
              resultWrapper.children().remove();

              if (jQuery.isEmptyObject(data)) {
                resultWrapper.append(nothingFoundTemplate);
                return;
              }

              for (var identifier in data) {
                var details = data[identifier];

                resultWrapper.append(itemTemplate.replace('[title]', details.title).replace('[link]', details.link));
              }
            }
          },
          complete: function (jqxhr, status) {
            if (status !== 'abort') {
              currentRunningSearchRequest = null;
            }
          }
        });
      }
    }).on('mouseover', function (event) {
      button.get(0).isHovered = true;
    }).on('mouseout', function (event) {
      button.get(0).isHovered = false;
    });

    input.on('blur', function (event) {
      if (input.val().length <= 0) {
        if (button.get(0).isHovered) {
          input.focus();
        } else if (resultWrapper.get(0).style.display !== 'none') {
          resultWrapper.slideUp(function() {
            container.removeClass('active');
          });
        } else {
          container.removeClass('active');
        }
      }
    }).on('keydown', function (event) {
      if (event.key === 'Escape') {
        input.val('');
        
        if (resultWrapper.get(0).style.display !== 'none') {
          resultWrapper.slideUp(function() {
            container.removeClass('active');
          });
        } else {
          container.removeClass('active');
        }
      } else if (event.key === 'Enter') {
        button.trigger('click');

        event.preventDefault();
        event.stopImmediatePropagation();
        return false;
      } else if (event.which === 8 || event.which === 9 || event.which === 32 || (event.which >= 48 && event.which <= 90) || (event.which >= 96 && event.which <= 111) || (event.which >= 160 && event.which <= 164) || event.which === 170 || event.which === 171 || (event.which >= 186 && event.which <= 190)) {
        // Hacky way to check if alphanumerical or special chars have been pressed
        button.trigger('click');
      }
    }).on('change', function (event) {
      console.log(event);
      button.trigger('click');
    });
  });
})